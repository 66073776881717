function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) {
    if ("string" == typeof r)
        return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
} }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++)
    n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) {
    var e, n, i, u, a = [], f = !0, o = !1;
    try {
        if (i = (t = t.call(r)).next, 0 === l) {
            if (Object(t) !== t)
                return;
            f = !1;
        }
        else
            for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0)
                ;
    }
    catch (r) {
        o = !0, n = r;
    }
    finally {
        try {
            if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u))
                return;
        }
        finally {
            if (o)
                throw n;
        }
    }
    return a;
} }
function _arrayWithHoles(r) { if (Array.isArray(r))
    return r; }
import { maskitoDateOptionsGenerator, maskitoNumberOptionsGenerator, maskitoAddOnFocusPlugin, maskitoCaretGuard, maskitoPrefixPostprocessorGenerator, maskitoRemoveOnBlurPlugin, maskitoPostfixPostprocessorGenerator } from "@maskito/kit";
export var digitsOnlyMask = {
    mask: /^\d+$/
};
function createCompletePhoneInsertionPreprocessor() {
    var trimPrefix = function trimPrefix(value) {
        return value.replace(/^(\+?7?\s?8?)\s?/, "");
    };
    var countDigits = function countDigits(value) {
        return value.replaceAll(/\D/g, "").length;
    };
    return function (_ref) {
        var elementState = _ref.elementState, data = _ref.data;
        var value = elementState.value, selection = elementState.selection;
        return {
            elementState: {
                selection: selection,
                value: countDigits(value) > 11 ? trimPrefix(value) : value
            },
            data: countDigits(data) >= 11 ? trimPrefix(data) : data
        };
    };
}
export var phoneMask = {
    mask: ["+", "7", " ", "(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
    postprocessors: [
        // non-removable country prefix
        maskitoPrefixPostprocessorGenerator("+7 ")
    ],
    preprocessors: [createCompletePhoneInsertionPreprocessor()],
    plugins: [maskitoAddOnFocusPlugin("+7 "), maskitoRemoveOnBlurPlugin("+7 "),
        // Forbids to put caret before non-removable country prefix
        // But allows to select all value!
        maskitoCaretGuard(function (value, _ref2) {
            var _ref3 = _slicedToArray(_ref2, 2), from = _ref3[0], to = _ref3[1];
            return [from === to ? "+7 ".length : 0, value.length];
        })]
};
export var patternDateMask = function patternDateMask(_ref4) {
    var _ref4$mode = _ref4.mode, mode = _ref4$mode === void 0 ? "dd/mm/yyyy" : _ref4$mode, separator = _ref4.separator, max = _ref4.max, min = _ref4.min;
    return maskitoDateOptionsGenerator({
        mode: mode,
        separator: separator,
        max: max,
        min: min
    });
};
export var maskNumberOptions = function maskNumberOptions(_ref5) {
    var min = _ref5.min, max = _ref5.max;
    return maskitoNumberOptionsGenerator({
        min: min,
        max: max
    });
};
export var moneyMask = maskitoNumberOptionsGenerator({
    thousandSeparator: " "
});
export var walletMask = {
    postprocessors: [maskitoPostfixPostprocessorGenerator(" ₽")]
};
export { maskitoNumberOptionsGenerator };
