export var navlinks = {
    root: "/",
    login: function login() {
        return navlinks.root.concat("login/");
    },
    page404: function page404() {
        return navlinks.root.concat("404/");
    },
    catalog: {
        root: function root() {
            return navlinks.root.concat("catalog/");
        },
        landing: function landing() {
            return navlinks.catalog.root().concat("landing/");
        },
        byId: function byId(_ref) {
            var id = _ref.id;
            return navlinks.catalog.root().concat(id, "/");
        },
        cart: {
            root: function root() {
                return navlinks.catalog.root().concat("cart/");
            }
        },
        checkout: {
            root: function root() {
                return navlinks.catalog.root().concat("checkout/");
            }
        },
        items: {
            root: function root() {
                return navlinks.catalog.root().concat("items/");
            },
            cars: function cars() {
                return navlinks.catalog.root().concat("cars/");
            },
            byId: function byId(_ref2) {
                var id = _ref2.id;
                return navlinks.catalog.items.root().concat(id);
            },
            byPath: function byPath(path) {
                return navlinks.catalog.root().concat(path);
            }
        }
    },
    admin: {
        root: function root() {
            return navlinks.root.concat("admin/");
        },
        about: function about() {
            return navlinks.admin.root().concat("/about");
        }
    },
    legacy_app: {
        root: function root() {
            return "https://lk-dev.smfox.ru/";
        },
        costEstimate: function costEstimate() {
            return navlinks.legacy_app.root().concat("client/new-application/cost-estimate");
        },
        profile: function profile() {
            return navlinks.legacy_app.root().concat("client/profile/");
        },
        companySettings: function companySettings() {
            return navlinks.legacy_app.root().concat("client/settings/company/");
        },
        guide: function guide() {
            return navlinks.legacy_app.root().concat("client/guide/");
        },
        garage: function garage() {
            return navlinks.legacy_app.root().concat("client/garage/");
        },
        support: function support() {
            return navlinks.legacy_app.root().concat("client/support/");
        },
        tickets: function tickets() {
            return navlinks.legacy_app.root().concat("client/applications/tikets-listing");
        }
    },
    fng_site: {
        root: function root() {
            return "https://fngroup.ru/";
        },
        about: function about() {
            return navlinks.legacy_app.root().concat("about/");
        },
        pressCenter: function pressCenter() {
            return navlinks.fng_site.root().concat("press-center/");
        },
        ourProjects: function ourProjects() {
            return navlinks.fng_site.root().concat("press-center/operating_experience/");
        },
        video: function video() {
            return navlinks.fng_site.root().concat("press-center/video/");
        },
        contacts: function contacts() {
            return navlinks.fng_site.root().concat("contacts/");
        },
        serviceGeography: function serviceGeography() {
            return navlinks.fng_site.root().concat("service_geography/");
        },
        requisites: function requisites() {
            return navlinks.fng_site.root().concat("about/requisites/");
        },
        parts: function parts() {
            return navlinks.fng_site.root().concat("construction_service/spare_parts/");
        },
        warranty: function warranty() {
            return navlinks.fng_site.root().concat("warranty/");
        },
        career: function career() {
            return navlinks.fng_site.root().concat("about/career/");
        }
    }
};
